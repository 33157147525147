.App {
  text-align: center;
}

body {
  /* Q: como se hacia para el scroll suaviazado? */
  /* A: https://css-tricks.com/snippets/jquery/smooth-scrolling/ */
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.titleh {
  font-size: 1.75rem !important;
  max-height: 1.75rem;
  /* El texto debe estar centrado horizontalmente */
  text-align: center;
}

.messages {
  width: auto;
  overflow-y: scroll;
  gap: 10px;
  height: calc(90vh - 1.75rem);
  scroll-behavior: smooth;
}

/* Q: como detecto el ultimo elemeto para ponerle un margin bottom de 15px? */
/* A: https://stackoverflow.com/questions/487073/check-if-element-is-last-child-in-javascript */
.messages > div:last-child {
  margin-bottom: 20px;
}

.sendmsg {
  width: auto;
  height: 10vh;
}

.avatar {
  max-width: 50px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  height: auto;
}
.message__content {
  margin-left: 10px;
}

.message__content_user {
  margin-left: 0;
  margin-right: 10px;
  text-align: right;
}

.messageuser {
  /* El mensaje debe verse en la derecha */
  background-color: #61dafb;
  color: #282c34;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.messagegpt {
  /* El mensaje debe verse en la izquierda */
  background-color: #282c34;
  color: #61dafb;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* El texto debe estar centrado verticalmente */
  justify-content: center;
  animation: loading1 1s infinite;
}
@keyframes loading1 {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
  
}

.message__avatar {
  height: 100%;
}

.confige {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #282c34;
    background-color: #002b36;
    box-shadow: 3px 16px 43px -1px rgba(0,0,0,0.69);
    padding: 10px;
    min-height: 100px;
    min-width: 300px;
    color: #72948a;
    transition: all 0.5s ease;
    
}
.centrao {
/*   width: 100%; */
  text-align: center;
}
.btn-info {
  width: 100%;
}